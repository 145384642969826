import styles from "./Navigation.module.css"

export default function Navigation() {
    const navItems = [
        {name: "Über mich", borderColor: "#E4DF9E"},
        {name: "Projekte", borderColor: "#D0965E"},
        {name: "Werdegang", borderColor: "#6D8A77"}
    ]

    return (
        <article className={styles.navContainer}>
            {
                navItems.map(navItem => {
                    return (
                        <div key={navItem.name}>
                            <p>{navItem.name}</p>
                            <div style={{ borderBottom: `0.5rem solid ${navItem.borderColor}`}} className={styles.border}></div>
                        </div>
                    )
                })
            }

        </article>
    )
}