import styles from "./Menu.module.css";
import Sun from "./Planets/Sun";
import Navigation from "./Navigation";

export default function Menu() {
    return (
        <section className={styles.navigationContainer}>
            <Sun></Sun>
            <Navigation></Navigation>
        </section>
    )
}